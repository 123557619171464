import { Fragment } from "react";
import { Tab } from "@headlessui/react";
import Slider from "react-slick";
import "./portfolio.scss";

import korpus1 from "../../img/korpus/korpus1.jpeg";
import korpus2 from "../../img/korpus/korpus2.jpeg";
import korpus12 from "../../img/korpus/korpus12.jpeg";
import korpus13 from "../../img/korpus/korpus13.jpeg";
import korpus16 from "../../img/korpus/korpus16.jpeg";
import korpus17 from "../../img/korpus/korpus17.jpeg";
import korpus18 from "../../img/korpus/korpus18.jpeg";
import korpus20 from "../../img/korpus/korpus20.jpeg";
import korpus21 from "../../img/korpus/korpus21.jpeg";
import korpus23 from "../../img/korpus/korpus23.jpeg";
import korpus24 from "../../img/korpus/korpus24.jpeg";
import korpus25 from "../../img/korpus/korpus25.jpeg";
import korpus26 from "../../img/korpus/korpus26.jpeg";
import korpus27 from "../../img/korpus/korpus27.jpeg";
import korpus30 from "../../img/korpus/korpus30.jpg";
import korpus31 from "../../img/korpus/korpus31.jpg";
import korpus32 from "../../img/korpus/korpus32.jpg";
import korpus33 from "../../img/korpus/korpus33.jpg";
import korpus34 from "../../img/korpus/korpus34.jpg";
import korpus35 from "../../img/korpus/korpus35.jpg";
import korpus36 from "../../img/korpus/korpus36.jpg";
import korpus37 from "../../img/korpus/korpus37.jpg";
import korpus38 from "../../img/korpus/korpus38.jpg";
import korpus39 from "../../img/korpus/korpus39.jpg";
import korpus40 from "../../img/korpus/korpus40.jpg";
import korpus41 from "../../img/korpus/korpus41.jpg";
import korpus42 from "../../img/korpus/korpus42.jpg";
import korpus43 from "../../img/korpus/korpus43.jpg";
import korpus44 from "../../img/korpus/korpus44.jpg";
import korpus45 from "../../img/korpus/korpus45.jpg";
import korpus46 from "../../img/korpus/korpus46.jpg";
import korpus47 from "../../img/korpus/korpus47.jpg";
import korpus48 from "../../img/korpus/korpus48.jpg";
import korpus49 from "../../img/korpus/korpus49.jpg";
import korpus50 from "../../img/korpus/korpus50.jpg";
import korpus51 from "../../img/korpus/korpus51.jpg";
import korpus52 from "../../img/korpus/korpus52.jpg";
import korpus53 from "../../img/korpus/korpus53.jpg";
import korpus54 from "../../img/korpus/korpus54.jpg";

import kitchen1 from "../../img/kitchen/kitchen1.jpeg";
import kitchen3 from "../../img/kitchen/kitchen3.jpeg";
import kitchen8 from "../../img/kitchen/kitchen8.jpeg";
import kitchen9 from "../../img/kitchen/kitchen9.jpeg";
import kitchen10 from "../../img/kitchen/kitchen10.jpeg";
import kitchen11 from "../../img/kitchen/kitchen11.jpeg";
import kitchen12 from "../../img/kitchen/kitchen12.jpeg";
import kitchen13 from "../../img/kitchen/kitchen13.jpeg";
import kitchen14 from "../../img/kitchen/kitchen14.jpeg";
import kitchen15 from "../../img/kitchen/kitchen15.jpeg";
import kitchen17 from "../../img/kitchen/kitchen17.jpeg";
import kitchen18 from "../../img/kitchen/kitchen18.jpeg";
import kitchen19 from "../../img/kitchen/kitchen19.jpeg";
import kitchen22 from "../../img/kitchen/kitchen22.jpeg";
import kitchen23 from "../../img/kitchen/kitchen23.jpeg";
import kitchen24 from "../../img/kitchen/kitchen24.jpeg";
import kitchen25 from "../../img/kitchen/kitchen25.jpeg";
import kitchen26 from "../../img/kitchen/kitchen26.jpeg";
import kitchen28 from "../../img/kitchen/kitchen28.jpeg";
import kitchen29 from "../../img/kitchen/kitchen29.jpg";
import kitchen30 from "../../img/kitchen/kitchen30.jpg";
import kitchen31 from "../../img/kitchen/kitchen31.jpg";
import kitchen32 from "../../img/kitchen/kitchen32.jpg";
import kitchen33 from "../../img/kitchen/kitchen33.jpg";
import kitchen34 from "../../img/kitchen/kitchen34.jpg";
import kitchen35 from "../../img/kitchen/kitchen35.jpg";
import kitchen36 from "../../img/kitchen/kitchen36.jpg";
import kitchen37 from "../../img/kitchen/kitchen37.jpg";
import kitchen38 from "../../img/kitchen/kitchen38.jpg";
import kitchen39 from "../../img/kitchen/kitchen39.jpg";
import kitchen40 from "../../img/kitchen/kitchen40.jpg";
import kitchen41 from "../../img/kitchen/kitchen41.jpg";
import kitchen42 from "../../img/kitchen/kitchen42.jpg";
import kitchen43 from "../../img/kitchen/kitchen43.jpg";
import kitchen44 from "../../img/kitchen/kitchen44.jpg";
import kitchen45 from "../../img/kitchen/kitchen45.jpg";
import kitchen46 from "../../img/kitchen/kitchen46.jpg";
import kitchen47 from "../../img/kitchen/kitchen47.jpg";

import vstroy1 from "../../img/vstroy/vstroy1.jpeg";
import vstroy2 from "../../img/vstroy/vstroy2.jpeg";
import vstroy3 from "../../img/vstroy/vstroy3.jpeg";
import vstroy4 from "../../img/vstroy/vstroy4.jpeg";
import vstroy5 from "../../img/vstroy/vstroy5.jpeg";
import vstroy6 from "../../img/vstroy/vstroy6.jpeg";
import vstroy10 from "../../img/vstroy/vstroy10.jpeg";
import vstroy11 from "../../img/vstroy/vstroy11.jpeg";
import vstroy12 from "../../img/vstroy/vstroy12.jpeg";
import vstroy13 from "../../img/vstroy/vstroy13.jpeg";
import vstroy14 from "../../img/vstroy/vstroy14.jpeg";
import vstroy15 from "../../img/vstroy/vstroy15.jpeg";
import vstroy16 from "../../img/vstroy/vstroy16.jpg";
import vstroy17 from "../../img/vstroy/vstroy17.jpg";
import vstroy18 from "../../img/vstroy/vstroy18.jpg";
import vstroy19 from "../../img/vstroy/vstroy19.jpg";

import bathroom1 from "../../img/bathroom/bathroom1.jpeg";
import bathroom2 from "../../img/bathroom/bathroom2.jpeg";
import bathroom3 from "../../img/bathroom/bathroom3.jpeg";
import bathroom4 from "../../img/bathroom/bathroom4.jpeg";
import bathroom5 from "../../img/bathroom/bathroom5.jpeg";
import bathroom7 from "../../img/bathroom/bathroom7.jpg";
import bathroom8 from "../../img/bathroom/bathroom8.jpg";
import bathroom9 from "../../img/bathroom/bathroom9.jpg";
import bathroom10 from "../../img/bathroom/bathroom10.jpg";
import bathroom11 from "../../img/bathroom/bathroom11.jpg";
import bathroom12 from "../../img/bathroom/bathroom12.jpg";
import bathroom13 from "../../img/bathroom/bathroom13.jpg";
import bathroom14 from "../../img/bathroom/bathroom14.jpg";
import bathroom15 from "../../img/bathroom/bathroom15.jpg";
import bathroom16 from "../../img/bathroom/bathroom16.jpg";
import bathroom17 from "../../img/bathroom/bathroom17.jpg";
import bathroom18 from "../../img/bathroom/bathroom18.jpg";
import bathroom19 from "../../img/bathroom/bathroom19.jpg";
import bathroom20 from "../../img/bathroom/bathroom20.jpg";
import bathroom21 from "../../img/bathroom/bathroom21.jpg";
import bathroom22 from "../../img/bathroom/bathroom22.jpg";
import bathroom23 from "../../img/bathroom/bathroom23.jpg";
import bathroom24 from "../../img/bathroom/bathroom24.jpg";
import bathroom25 from "../../img/bathroom/bathroom25.jpg";

const Portfolio = () => {
  const korpus = [
    korpus1,
    korpus2,
    korpus12,
    korpus13,
    korpus16,
    korpus17,
    korpus18,
    korpus20,
    korpus21,
    korpus23,
    korpus24,
    korpus25,
    korpus26,
    korpus27,
    korpus30,
    korpus31,
    korpus32,
    korpus33,
    korpus34,
    korpus35,
    korpus36,
    korpus37,
    korpus38,
    korpus39,
    korpus40,
    korpus41,
    korpus42,
    korpus43,
    korpus44,
    korpus45,
    korpus46,
    korpus47,
    korpus48,
    korpus49,
    korpus50,
    korpus51,
    korpus52,
    korpus53,
    korpus54
  ];

  const kitchen = [
    kitchen1,
    kitchen3,
    kitchen8,
    kitchen9,
    kitchen10,
    kitchen11,
    kitchen12,
    kitchen13,
    kitchen14,
    kitchen15,
    kitchen17,
    kitchen18,
    kitchen19,
    kitchen22,
    kitchen23,
    kitchen24,
    kitchen25,
    kitchen26,
    kitchen28,
    kitchen29,
    kitchen30,
    kitchen31,
    kitchen32,
    kitchen33,
    kitchen34,
    kitchen35,
    kitchen36,
    kitchen37,
    kitchen38,
    kitchen39,
    kitchen40,
    kitchen41,
    kitchen42,
    kitchen43,
    kitchen44,
    kitchen45,
    kitchen46,
    kitchen47
  ];

  const vstroy = [
    vstroy1,
    vstroy2,
    vstroy3,
    vstroy4,
    vstroy5,
    vstroy6,
    vstroy10,
    vstroy11,
    vstroy12,
    vstroy13,
    vstroy14,
    vstroy15,
    vstroy16,
    vstroy17,
    vstroy18,
    vstroy19,
  ];

  const bathroom = [
    bathroom1,
    bathroom2,
    bathroom3,
    bathroom4,
    bathroom5,
    bathroom7,
    bathroom8,
    bathroom9,
    bathroom10,
    bathroom11,
    bathroom12,
    bathroom13,
    bathroom14,
    bathroom15,
    bathroom16,
    bathroom17,
    bathroom18,
    bathroom19,
    bathroom20,
    bathroom21,
    bathroom22,
    bathroom23,
    bathroom24,
    bathroom25,
  ];

  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "0px",
    slidesToShow: document.body.clientWidth < 500 ? 1 : 3,
    speed: 400,
    autoplay: true,
    autoplaySpeed: 6000,
  };
  return (
    <Tab.Group defaultIndex={1}>
      <h3 className="text-3xl font-bold mb-10 lg:mb-20 text-center">
        Примеры работ
      </h3>
      <div className="flex justify-center text-xl lg:text-2xl mb-24">
        <Tab.List className="flex flex-col lg:flex-row items-center justify-between text-center">
        <Tab as={Fragment}>
            {({ selected }) => (
              <button
                className={
                  selected
                    ? "text-black border-b-4 border-green-950 mr-0 lg:mr-40 pb-2 outline-none"
                    : "mr-0 lg:mr-40 pb-2 hover:opacity-60 outline-none"
                }
              >
                Кухня
              </button>
            )}
          </Tab>
          <Tab as={Fragment}>
            {({ selected }) => (
              <button
                className={
                  selected
                    ? "text-black border-b-4 border-green-950 pb-2 outline-none mr-0 lg:mr-40"
                    : "mr-0 lg:mr-40 pb-2 hover:opacity-60 outline-none"
                }
              >
                Мебель для ванной
              </button>
            )}
          </Tab>
          <Tab as={Fragment}>
            {({ selected }) => (
              <button
                className={
                  selected
                    ? "text-black border-b-4 border-green-950 mr-0 lg:mr-40 pb-2 outline-none"
                    : "mr-0 lg:mr-40 pb-2 hover:opacity-60 outline-none"
                }
              >
                Корпусная мебель
              </button>
            )}
          </Tab>
          <Tab as={Fragment}>
            {({ selected }) => (
              <button
                className={
                  selected
                    ? "text-black border-b-4 border-green-950 pb-2 outline-none"
                    : "pb-2 hover:opacity-60 outline-none"
                }
              >
                Встроенная мебель
              </button>
            )}
          </Tab>
        </Tab.List>
      </div>

      <Tab.Panels className="mt-10 w-full">
      <Tab.Panel>
          <Slider {...settings}>
            {kitchen.map((item, i) => (
              <div key={i}>
                <img src={item} alt="example" />
              </div>
            ))}
          </Slider>
        </Tab.Panel>
        <Tab.Panel>
          <Slider {...settings}>
            {bathroom.map((item, i) => (
              <div key={i}>
                <img src={item} alt="example" />
              </div>
            ))}
          </Slider>
        </Tab.Panel>
        <Tab.Panel>
          <Slider {...settings}>
            {korpus.map((item, i) => (
              <div key={i}>
                <img src={item} alt="example" />
              </div>
            ))}
          </Slider>
        </Tab.Panel>
        <Tab.Panel>
          <Slider {...settings}>
            {vstroy.map((item, i) => (
              <div key={i}>
                <img src={item} alt="example" />
              </div>
            ))}
          </Slider>
        </Tab.Panel>
      </Tab.Panels>
    </Tab.Group>
  );
};

export default Portfolio;
