import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const ButtonContainer = styled.span`
  color: #000;
  position: fixed;
  bottom: 32px;
  right: 32px;
  align-items: center;
  height: clamp(1.5rem, -0.136rem + 8.18vw, 6rem);
  width: clamp(1.5rem, -0.136rem + 8.18vw, 6rem);
  justify-content: center;
  z-index: 1000;
  cursor: pointer;
  animation: fadeIn 0.3s;
  opacity: 1;
  background: rgb(195 244 1);
  border-radius: 48px;
  transition: opacity 0.4s, color ease-in-out 0.2s, background ease-in-out 0.2s;
  display: ${({ isScrollButtonVisible }) =>
    isScrollButtonVisible ? 'flex' : 'none'};

  &:hover {
    opacity: 1;
  }
`;

const BackToTopButton = () => {
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    const checkScrollHeight = () => {
      if (!showButton && window.pageYOffset > 400) {
        setShowButton(true);
      } else if (showButton && window.pageYOffset <= 400) {
        setShowButton(false);
      }
    };

    window.addEventListener('scroll', checkScrollHeight);
    return () => {
      window.removeEventListener('scroll', checkScrollHeight);
    };
  }, [showButton]);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <ButtonContainer isScrollButtonVisible={showButton} onClick={scrollToTop}>
      <svg
        style={{ width: 'clamp(0.75rem, -0.068rem + 4.09vw, 3rem)', height: 'clamp(0.75rem, -0.068rem + 4.09vw, 3rem)' }}
        viewBox="0 0 24 24"
        fill="none"
        stroke="#000000"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M12 19V5M5 12l7-7 7 7" />
      </svg>
    </ButtonContainer>
  );
};

export default BackToTopButton;