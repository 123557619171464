import { useEffect } from "react";
import Slider from "react-slick";
import "./tables.scss";

import calypso from "../../img/tables/calypso.jpg";
import delano from "../../img/tables/delano.jpg";
import delli from "../../img/tables/delli.jpg";
import elliot from "../../img/tables/elliot.jpg";
import faust from "../../img/tables/faust.jpg";
import grace from "../../img/tables/grace.jpg";
import lars from "../../img/tables/lars.jpg";
import levante from "../../img/tables/levante.jpg";
import lorenta from "../../img/tables/lorenta.jpg";
import marbel from "../../img/tables/marbel.jpg";
import martino from "../../img/tables/martino.jpg";
import matteo from "../../img/tables/matteo.jpg";
import niagara from "../../img/tables/niagara.jpg";
import palermo from "../../img/tables/palermo.jpg";
import santino from "../../img/tables/santino.jpg";
import santorini from "../../img/tables/santorini.jpg";
import sorrento from "../../img/tables/sorrento.jpg";
import terni from "../../img/tables/terni.jpg";
import torino from "../../img/tables/torino.jpg";

const Tables = () => {
  const settings = {
    className: "tables-center",
    centerMode: true,
    infinite: true,
    centerPadding: "0px",
    slidesToShow: 1,
    speed: 400,
    autoplay: true,
    autoplaySpeed: 6000,
    adaptiveHeight: true,
  };

  const tables = [
    calypso,
    delano,
    delli,
    elliot,
    faust,
    grace,
    lars,
    levante,
    lorenta,
    marbel,
    martino,
    matteo,
    niagara,
    palermo,
    santino,
    santorini,
    sorrento,
    terni,
    torino,
  ];

  useEffect(() => {
    const initSlider = () => {
      const slider = document.querySelector(".slider-container");
      if (slider) {
        window.dispatchEvent(new Event("resize"));
      }
    };

    if (document.readyState === "complete") {
      initSlider();
    } else {
      window.addEventListener("load", initSlider);
    }

    return () => {
      window.removeEventListener("load", initSlider);
    };
  }, []);

  return (
    <div>
      <h3 className="mt-10 text-3xl text-white font-bold mb-10 lg:mb-20 text-center">
        Обеденные столы
      </h3>
      <div className="tables-slider-container">
        <Slider className="tables-slider" {...settings}>
          {tables.map((item, i) => (
            <div key={i} style={{ height: "609px" }}>
              <img src={item} alt="example" />
            </div>
          ))}
        </Slider>
      </div>
      <div>
        <div className="mt-10 text-justify text-white max-w-[90%] mx-auto">
          <h3 className="mb-3 text-2xl text-center">
            Здравствуйте, дорогие друзья!{" "}
            <strong className="text-white">«Мебельный дом Государев»</strong>{" "}
            приветствует вас!
          </h3>
          <p className="mb-3">
            Наш онлайн-магазин является представителем ведущей мебельной фабрики{" "}
            <strong className="text-white">«MGS»</strong> Пензенской области.
            Сегодня мы представляем вам обеденные столы собственного
            производства из керамогранита.
          </p>
          <p className="mb-3">
            Коротко о столах: столешница является составной. Верхний слой —
            керамогранит{" "}
            <strong className="text-white">3,5 мм, 6 мм, 12 мм</strong>, не
            подвержен воздействию жидкостей, сверхустойчив к царапинам,
            УФ-лучам, абразивам и агрессивным химическим моющим средствам, не
            впитывает запахи и не оставляет пятен. Безупречный, богатый вид
            позволяет обходиться без скатерти. Основа столешницы — плита HPL{" "}
            <strong className="text-white">12 мм</strong> (пластик высокого
            давления), также не подвержена воздействию разного рода жидкостей.
          </p>
          <p className="mb-3">
            Для рамы столешницы используется металлический профиль{" "}
            <strong className="text-white">60х30х1,2 мм</strong>. Имеет
            регулировку и окрашивается в порошковую краску с устойчивым
            полимерным слоем в цвет опоры.
          </p>
          <p className="mb-3">
            Для изготовления опоры используется гнутый листовой металл толщиной{" "}
            <strong className="text-white">2 мм, 8 мм</strong>.
          </p>
          <p className="mb-3">
            Друзья, столы с такими показателями украсят и надёжно прослужат вам
            не только на вашей кухне, но также на вашем дворе в зоне барбекю, в
            беседке или под любым другим навесом. Премиум-качество и богатый вид
            позволяют их использование в офисе, в кабинете для брифинга или даже
            в кабинете руководства, ведь они очень практичны и просты в уходе.
          </p>
          <p className="mb-3">
            Итак, дорогие друзья, в нашем онлайн-магазине вы можете узнать, что
            есть в наличии, а также произвести заказ понравившейся вам модели
            стола, выбрав по вашему желанию декоративный слой из керамогранита
            из нашего каталога (менеджер вам вышлет по запросу), а также можете
            выбрать цвет опоры стола.
          </p>
          <p className="mb-3">
            Друзья, приобретая у нас, будьте уверены, что получите изделие
            премиум-качества по среднерыночной цене.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Tables;
