import { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";

const Steps = () => {
  return (
    <>
      <div className="flex flex-col justify-center cursor-pointer stepx-prev-arr">
        <svg
          width="56"
          height="56"
          viewBox="0 0 56 56"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M46.667 25.6667V30.3334H18.667L31.5003 43.1667L28.187 46.48L9.70699 28L28.187 9.52002L31.5003 12.8334L18.667 25.6667H46.667Z"
            fill="white"
          />
        </svg>
      </div>
      <Swiper
        navigation={{ nextEl: ".steps-next-arr", prevEl: ".stepx-prev-arr" }}
        spaceBetween={140}
        slidesPerView={window.innerWidth < 600 ? 1 : 3}
        slide="true"
        modules={[Navigation]}
      >
        <SwiperSlide>
          <div className="flex flex-col justify-center items-center text-white max-w-[300px]">
            <span className="flex text-5xl font-bold text-black justify-center items-center bg-green-950 p-5 rounded-full w-20 h-20 ">
              1
            </span>
            <h2 className="text-lg lg:text-2xl mt-4 mb-6">Брифинг</h2>
            <p className="text-center text-sm lg:text-lg">
              Предварительно обсудим потребности и пожелания, подберем
              подходящие материалы и фурнитуру.
            </p>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="flex flex-col justify-center items-center text-white max-w-[300px]">
            <span className="flex text-5xl font-bold text-black justify-center items-center bg-green-950 p-5 rounded-full w-20 h-20 ">
              2
            </span>
            <h2 className="text-lg lg:text-2xl mt-4 mb-6">
              Согласование проекта
            </h2>
            <p className="text-center text-sm lg:text-lg">
              В течении 3-х дней подготовим для вас коммерческое предложение с
              проектами, разработанными специально для вас.
            </p>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="flex flex-col justify-center items-center text-white max-w-[300px]">
            <span className="flex text-5xl font-bold text-black justify-center items-center bg-green-950 p-5 rounded-full w-20 h-20 ">
              3
            </span>
            <h2 className="text-lg lg:text-2xl mt-4 mb-6">Вызов замерщика</h2>
            <p className="text-center text-sm lg:text-lg">
              Согласовываем дату выезда. Стоимость услуги - 1500 ₽, в случае
              заключения договора сумма переносится в счет предоплаты.
            </p>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="flex flex-col justify-center items-center text-white max-w-[300px]">
            <span className="flex text-5xl font-bold text-black justify-center items-center bg-green-950 p-5 rounded-full w-20 h-20 ">
              4
            </span>
            <h2 className="text-lg lg:text-2xl mt-4 mb-6">Предоплата</h2>
            <p className="text-center text-sm lg:text-lg">
              Получаем предоплату в размере от 20% от стоимости изделия и
              заключаем договор.
            </p>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="flex flex-col justify-center items-center text-white max-w-[300px]">
            <span className="flex text-5xl font-bold text-black justify-center items-center bg-green-950 p-5 rounded-full w-20 h-20 ">
              5
            </span>
            <h2 className="text-lg lg:text-2xl mt-4 mb-6">
              Реализация проекта
            </h2>
            <p className="text-center text-sm lg:text-lg">
              Изготавливаем мебель в течении 5-10 рабочих дней.
            </p>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="flex flex-col justify-center items-center text-white max-w-[300px]">
            <span className="flex text-5xl font-bold text-black justify-center items-center bg-green-950 p-5 rounded-full w-20 h-20 ">
              6
            </span>
            <h2 className="text-lg lg:text-2xl mt-4 mb-6">Доставка и сборка</h2>
            <p className="text-center text-sm lg:text-lg">
              Согласовываем дату и время доставки и сборки. Осуществляем
              доставку в назначенный срок.
            </p>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="flex flex-col justify-center items-center text-white max-w-[300px] px-6">
            <span className="flex text-5xl font-bold text-black justify-center items-center bg-green-950 p-5 rounded-full w-20 h-20 ">
              7
            </span>
            <h2 className="text-lg lg:text-2xl mt-4 mb-6">Полная оплата</h2>
            <p className="text-center text-sm lg:text-lg">
              Оплата полной стоимости изделия осуществляется при доставке заказа
              на согласованный адрес.
            </p>
          </div>
        </SwiperSlide>
      </Swiper>
      <div className="flex flex-col justify-center cursor-pointer steps-next-arr">
        <svg
          width="56"
          height="56"
          viewBox="0 0 56 56"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.33203 25.6667V30.3334H37.332L24.4987 43.1667L27.812 46.48L46.292 28L27.812 9.52002L24.4987 12.8334L37.332 25.6667H9.33203Z"
            fill="white"
          />
        </svg>
      </div>
    </>
  );
};

export default Steps;
