import { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";

import two from "../../img/reviews/2.png"
import three from "../../img/reviews/3.png"

const Reviews = () => {
  return (
    <>
      <div className="flex flex-col justify-center cursor-pointer reviews-prev-arr px-0 lg:px-10">
        <svg
          width="56"
          height="56"
          viewBox="0 0 56 56"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M46.667 25.6667V30.3334H18.667L31.5003 43.1667L28.187 46.48L9.70699 28L28.187 9.52002L31.5003 12.8334L18.667 25.6667H46.667Z"
            fill="black"
          />
        </svg>
      </div>
      <Swiper
        navigation={{
          nextEl: ".reviews-next-arr",
          prevEl: ".reviews-prev-arr",
        }}
        slidesPerView={1}
        modules={[Navigation]}
        className="max-w-[200px] lg:max-w-xl"
      >
        <SwiperSlide>
          <div className="flex flex-col justify-center items-center text-white max-w-[200px] lg:max-w-[300px]">
            <img src={two} alt="reviews" />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="flex flex-col justify-center items-center text-white max-w-[200px] lg:max-w-[300px]">
            <img src={three} alt="reviews" />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="flex flex-col justify-center items-center text-white max-w-[200px] lg:max-w-[300px]">
            <img src={two} alt="reviews" />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="flex flex-col justify-center items-center text-white max-w-[200px] lg:max-w-[300px]">
            <img src={three} alt="reviews" />
          </div>
        </SwiperSlide>
      </Swiper>
      <div className="flex flex-col justify-center cursor-pointer reviews-next-arr px-0 lg:px-10">
        <svg
          width="56"
          height="56"
          viewBox="0 0 56 56"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.33203 25.6667V30.3334H37.332L24.4987 43.1667L27.812 46.48L46.292 28L27.812 9.52002L24.4987 12.8334L37.332 25.6667H9.33203Z"
            fill="black"
          />
        </svg>
      </div>
    </>
  );
};

export default Reviews;
