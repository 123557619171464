import React from "react";
import ReactInputMask from "react-input-mask";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import "./contactus.scss";

const ContactUs = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    console.log(data);
    const id = toast.loading("Отправляем письмо...");
    try {
      await fetch("../phpmailer/contactus.php", {
        body: JSON.stringify(data),
        method: "POST",
      });

      reset();

      toast.update(id, {
        render: "Ваша письмо отправлено успешно! Скоро мы с вами свяжемся!",
        type: "success",
        isLoading: false,
        autoClose: 5000,
        closeButton: true,
      });
    } catch (error) {
      toast.update(id, {
        render: error.data.message,
        type: "error",
        isLoading: false,
        autoClose: 5000,
        closeButton: true,
      });
    }
  };

  return (
    <form className="flex flex-col" onSubmit={handleSubmit(onSubmit)}>
      <input
        className="mt-6 rounded-xl p-4"
        placeholder="Имя"
        type="name"
        {...register("name", {
          required: true,
          validate: (value) => value !== "",
        })}
      />
      {errors.name && (
        <p className="text-red-500 text-sm">Укажите как Вас зовут</p>
      )}

      <ReactInputMask
        {...register("phone", {
          required: true,
        })}
        type="phone"
        mask="+7 999 999 99 99"
        maskPlaceholder=""
        name="phone"
        className="mt-6 rounded-xl p-4"
        placeholder="Номер телефона"
      />
      {errors.phone && (
        <p className="text-red-500 text-sm">Введите номер телефона</p>
      )}

      <input
        className="mt-6 rounded-xl p-4"
        placeholder="Email"
        type="email"
        {...register("email", {
          required: true,
          validate: (value) => value !== "",
        })}
      />
      {errors.email && <p className="text-red-500 text-sm">Введите Email</p>}

      <textarea
        className="mt-6 rounded-xl p-4"
        rows="6"
        placeholder="Комментарий"
        {...register("text", {
          required: true,
          validate: (value) => value !== "",
        })}
      />
      {errors.text && (
        <p className="text-red-500 text-sm">
          Пустое сообщение нельзя отправить
        </p>
      )}
      <div className="flex justify-center mt-10 space-x-4">
        <button className="bg-green-950 font-bold px-8 py-4 rounded-xl hover:bg-black hover:text-white transition-all active:scale-95">
          Оставить заявку
        </button>
        <a href="tel:+79264224774">
          <div className="bg-green-950 font-bold px-8 py-4 rounded-xl hover:bg-black hover:text-white transition-all active:scale-95 contactus__call-button">
            Позвонить
          </div>
        </a>
      </div>
    </form>
  );
};

export default ContactUs;
