import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";
import ReactInputMask from "react-input-mask";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

export default function Modal() {
  let [isOpen, setIsOpen] = useState(false);

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    console.log(data);
    const id = toast.loading("Отправляем письмо...");
    try {
      await fetch("../phpmailer/cooperation.php", {
        body: JSON.stringify(data),
        method: "POST",
      });

      reset();

      toast.update(id, {
        render: "Ваша письмо отправлено успешно! Скоро мы с вами свяжемся!",
        type: "success",
        isLoading: false,
        autoClose: 5000,
        closeButton: true,
      });
    } catch (error) {
      toast.update(id, {
        render: error.data.message,
        type: "error",
        isLoading: false,
        autoClose: 5000,
        closeButton: true,
      });
    }
  };

  return (
    <>
      <div>
        <button
          type="button"
          onClick={openModal}
          className="bg-black rounded-xl px-6 py-2 text-white text-opacity-80 hover:text-opacity-100 active:scale-95 transition-all"
        >
          Сотрудничество
        </button>
      </div>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-lg transform overflow-hidden rounded-2xl bg-white p-4 lg:p-12 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-xl font-bold text-center leading-6 text-gray-900 mb-6"
                  >
                    Приглашаем на взаимовыгодное сотрудничество
                  </Dialog.Title>
                  <div className="mb-6 text-center">
                    <p className="text-sm text-gray-500 ">
                      Условия сотрудничества обговаривается лично
                    </p>
                  </div>

                  <form
                    className="flex flex-col"
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    <input
                      className="mt-6 rounded-xl p-4 border border-gray-800"
                      placeholder="Имя"
                      type="name"
                      {...register("name", {
                        required: true,
                        validate: (value) => value !== "",
                      })}
                    />
                    {errors.name && (
                      <p className="text-red-500 text-sm">
                        Укажите как Вас зовут
                      </p>
                    )}

                    <ReactInputMask
                      {...register("phone", {
                        required: true,
                      })}
                      type="phone"
                      mask="+7 999 999 99 99"
                      maskPlaceholder=""
                      name="phone"
                      className="mt-6 rounded-xl p-4 border border-gray-800"
                      placeholder="Номер телефона"
                    />
                    {errors.phone && (
                      <p className="text-red-500 text-sm">
                        Введите номер телефона
                      </p>
                    )}

                    <input
                      className="mt-6 rounded-xl p-4 border border-gray-800"
                      placeholder="Email"
                      type="email"
                      {...register("email", {
                        required: true,
                        validate: (value) => value !== "",
                      })}
                    />
                    {errors.email && (
                      <p className="text-red-500 text-sm">Введите Email</p>
                    )}

                    <textarea
                      className="mt-6 rounded-xl p-4 border border-gray-800 resize-none"
                      rows="6"
                      placeholder="Комментарий"
                      {...register("text", {
                        required: true,
                        validate: (value) => value !== "",
                      })}
                    />
                    {errors.text && (
                      <p className="text-red-500 text-sm">
                        Пустое сообщение нельзя отправить
                      </p>
                    )}
                    <div className="flex justify-center mt-10">
                      <button className="bg-green-950 font-bold px-8 py-4 rounded-xl hover:bg-black hover:text-white transition-all active:scale-95">
                        Оставить заявку
                      </button>
                    </div>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
